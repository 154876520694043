import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import scrollTo from 'gatsby-plugin-smoothscroll'
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const Recruit = ({location}) => {

  const data = useStaticQuery(graphql`
    query {
      recruit: file(relativePath: { eq: "illust/recruit.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      topmessage: file(relativePath: { eq: "iharasan-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      name: file(relativePath: { eq: "president_nameplate2.png" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      develop: file(relativePath: { eq: "icons/pc.svg" }) {
        publicURL
      },
    }
  `)

  const Br = () => <br className="d-lg-block d-none" />

  const Hr = () => <hr className="dots-hr my-4" />

  return(
    <Layout location={location} title="採用情報" >
      <SEO title="採用情報" description="弊社の採用に関する情報を掲載しております。"/>

      <div className="page-head">
        <Container expand="lg">
          <BackgroundImage fluid={data.recruit.childImageSharp.fluid} id="page-head-image" >
            <h2>採用情報</h2>
            <p>
              お客様のビジネス環境のご⽀援・課題解決のため、<Br />
              最適なソリューションをコンサルティング、システム開発、 インフラ構築から運⽤・サポートまでワンストップで提供しています。<Br />
              案件のほとんどがプライム案件で、⾃社にて開発を進めていきます。
            </p>
            <p className="mt-5"><strong>募集職種</strong></p>
            <Button
              variant="info"
              onClick={() => scrollTo('#application-developer')}
            >
              アプリケーションエンジニア / 中途{`　`}<FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </BackgroundImage>
        </Container>
        <div className="bottom-border" />
      </div>

      <Container expand="lg">
        <h3 className="text-center mb-5">トップメッセージ</h3>
        <Row className="mb-6">
          <Col lg={3} className="text-center">
            <Img fluid={data.topmessage.childImageSharp.fluid} className="mx-auto mb-5" alt="トップメッセージ"/>
          </Col>
          <Col lg="auto">
            <p>
              当社はエンドユーザーの⽅々とやり取りをする直接取引にこだわっています。<Br />
              ⼀次請けとなるプロジェクトはリスクもありますが、⾃分たちの⼒で開発したいという強い思いがあります。<Br />
              ⾃社で開発できるからこそ、技術・ノウハウが蓄積できる。<Br />
              その蓄積したものでユーザーに喜んでいただけるサービスを提供できると考えています。<Br />
              経験の有無、経験してきた案件内容ではなく、<Br />
              ユーザー⽬線に⽴って「ユーザーのための開発」に共感いただける⽅とお会いしたいと思っています。<Br />
            </p>
            <div className="text-right">
              <Img fluid={data.name.childImageSharp.fluid} className="ml-auto mt-3" alt="トップメッセージ"/>
            </div>
          </Col>
        </Row>

        <div id="application-developer"/>
        <h3 className="text-center">募集職種</h3>

        <div className="h2-subtitle my-5">
					<img src={data.develop.publicURL} alt="アプリケーションエンジニア/中途 募集要項 "/>
					<h2>アプリケーションエンジニア/中途 募集要項</h2>
					<p>Application Developer Job Description</p>
				</div>

        <div className="shadow-box">
          <Row>
            <Col lg={3}><p><strong>職種名</strong></p></Col>
            <Col lg><p>PG・SE</p></Col>
            <Col lg={12}><Hr /></Col>

            <Col lg={3}><p><strong>応募資格</strong></p></Col>
            <Col lg>
              <p>
                学歴︓⾼卒以上<Br />
                歓迎する経験・資格<Br />
                何かしらのシステム開発経験<Br />
                ※Java、JavaScript、Python、PHPでの開発経験をお持ちの⽅は優遇します。<Br />
              </p>
            </Col>
            <Col lg={12}><Hr /></Col>

            <Col lg={3}><p><strong>仕事内容</strong></p></Col>
            <Col lg>
              <p>
                EDIシステムや⾃社パッケージ、IoT関連システムの開発をしていただきます。<br />
                案件要件定義、基本設計、詳細設計、プログラミング、テスト、運⽤・保守など、<Br />
                上流⼯程から⼀括で案件を請け負う案件が9割を占めます。<br/>
                希望や適正に合わせて仕事をお任せします。医療、製薬、流通、⼩売、⾦融など様々です。<br/>
                2⼈以上のチームで仕事をしていくことがほとんどです。<br/>
                先輩社員のフォローがあるので安⼼して下さい。<br/>
                <br/>
                ■プロジェクトについて<br/>
                ⾃動⾞部品メーカー向けEDIシステム、<Br />
                ⾃社パッケージのクラウド型XML-EDI ｢EcoChange｣、<Br />
                IoTシステム、各種業務システムなど開発しています。<br/>
                AWSコンサルティングパートナーとして、<Br />
                クラウドを活⽤したシステム開発を積極的に⾏っており、<Br />
                クラウド上ので基盤構築・移⾏⽀援・運⽤⽀援も⾏っています。<br/>
                今後、EDI、IoT、AWSの分野を強化していきます。<br/>
                最新技術を⽤い、これらの分野を横断し、各チーム協⼒し合いながら、<Br />
                プロジェクトを実⾏していくこともあります。<br/>
                <br/>
                ■開発環境<br/>
                ⾔語︓Java、JavaScript、Python、PHP<br/>
                DB︓SQL Server、Oracle、PostgreSQL、MySQL<br/>
                OS︓Windows、Linux、UNIX<br/>
              </p>
            </Col>
            <Col lg="12"><Hr /></Col>

            <Col lg={3}><p><strong>雇⽤形態</strong></p></Col>
            <Col lg>
              <p>
                正社員<br />
                試⽤期間3ヶ⽉ ※試⽤期間中の待遇・給与は変動ありません。
              </p>
            </Col>
            <Col lg="12"><Hr /></Col>

            <Col lg={3}><p><strong>勤務地</strong></p></Col>
            <Col lg>
              <p>
                本社:各線名古屋駅徒歩10分程度<br />
                東京事業所︓東京メトロ銀座線「神田駅」6番出口より徒歩2分、JR「秋葉原駅」電気街口改札より徒歩8分<br/>
                ⻘森事業所︓⻘森市営バス⻘森県庁前徒歩1分、⻘森市営バス市役所前徒歩5分<br/>
                ※勤務地の希望は⼗分に考慮します。
              </p>
            </Col>
            <Col lg="12"><Hr /></Col>

            <Col lg={3}><p><strong>給与</strong></p></Col>
            <Col lg>
              <p>
                ⽉給23万円以上<br />
                ※給与の中には職務⼿当を含みます。<br/>
                ※能⼒・経験・前給を考慮の上、⾯談により決定します。
              </p>
            </Col>
            <Col lg="12"><Hr /></Col>

            <Col lg={3}><p><strong>休⽇・休暇</strong></p></Col>
            <Col lg>
              <p>
                ⼟・⽇・祝⽇<br/>
                年末年始休暇、夏季休暇<br/>
                有給休暇<br/>
                慶弔休暇<br/>
                産前産後休暇<br/>
                育児休暇<br/>
                看護休暇<br/>
                介護休暇
              </p>
            </Col>
            <Col lg="12"><Hr /></Col>

            <Col lg={3}><p><strong>福利厚⽣</strong></p></Col>
            <Col lg>
              <p>
                給与改定（年１回）<br/>
                賞与（年２回）<br/>
                交通費⽀給（上限︓3万5000円/⽉）<br/>
                社会保険完備（雇⽤保険、労災保険、健康保険、厚⽣年⾦保険）<br/>
                時間外⼿当<br/>
                役職⼿当<br/>
                保守⼿当<br/>
                出張⼿当<br/>
                私服通勤可<br/>
                在宅勤務制度あり<br/>
                育児・介護時短勤務制度あり<br/>
              </p>
            </Col>
            <Col lg="12"><Hr /></Col>
          </Row>
          <div className="my-5 text-center">
            <Button
              variant="info"
              className="ml-3 py-4 px-5"
              href="/recruitApplication/"
              target="_blank" rel="noopener noreferrer"
              >
              <span className="h2">エントリーはこちら</span>
            </Button>
          </div>
        </div>
      </Container>

    </Layout>
  )
}

export default Recruit